<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="ContractsOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <ContractsListingNew></ContractsListingNew>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import ContractsListing from "../../../components/Appic/ContractsListing";
    // import ExportTable from "../../../components/Appic/ExportTable";

    import LoadingComponent from "Components/Appic/LoadingComponent";
    import {mapFields} from "vuex-map-fields";
    // import ExportTableOnly from "Components/Appic/ExportTableOnly";

    const ContractsListingNew = () => ({
        component: import("../../../components/Appic/ContractsListingNew"),
        loading: LoadingComponent
    });
    const ExportTableOnly = () => import("../../../components/Appic/ExportTableOnly");

    export default {
        name: "ContractsOverview",
        components: {ExportTableOnly, ContractsListingNew},
        title: '',
        data() {
            return {
                loader: false
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        methods: {
            newContract(){
                let tab = window.open('/v1/contracts/add','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        mounted(){
            this.$title = this.$t('message.titles.contracts')
        }
    }
</script>

<style>
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>